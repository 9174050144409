import { GraphQLClient } from 'graphql-request';

export const processorClient = new GraphQLClient(
  process.env.NEXT_PUBLIC_PROCESSOR_GRAPHQL_API as string,
);

export const cacheClient = new GraphQLClient(
  process.env.NEXT_PUBLIC_STATECHAIN_CACHE_GRAPHQL_API as string,
);

export const lpProcessorClient = new GraphQLClient(
  process.env.NEXT_PUBLIC_LP_PROCESSOR_GRAPHQL_API as string,
);
