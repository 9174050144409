import 'mac-scrollbar/dist/mac-scrollbar.css';
import '@/shared/utils/prelude';
import '../styles/globals.css';
import '@rainbow-me/rainbowkit/styles.css';
import type { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider, QueryErrorResetBoundary } from '@tanstack/react-query';
import { GlobalScrollbar } from 'mac-scrollbar';
import { ErrorBoundary } from 'react-error-boundary';
import { Toaster } from 'sonner';
import FallbackLayout from '@/shared/components/organisms/FallbackLayout';
import { Provider as RainbowKitWrappedProvider } from '@/shared/components/RainbowKit';
import { PriceOracleProvider, FundingSDKProvider } from '@/shared/hooks';
import usePlausible from '@/shared/hooks/usePlausible';
import { PolkadotProvider } from '@/shared/hooks/usePolkadot';
import { getChainflipNetwork, initSentry } from '@/shared/utils';
import { defaultRainbowConfig } from '@/shared/utils/rainbowkit';
import EnsureAccount from '../components/EnsureAccount';
import { POLKADOT_JS_APP_NAME } from '../helpers/constants';
import { StateChainAccountProvider } from '../hooks/useStateChainAccount';
import useStore from '../hooks/useStore';
import { LP_APP_DOMAINS, LP_APP_PLAUSIBLE_DOMAIN } from '../utils/consts';

// TODO: remove when boost feature flag has been removed
process.env.BOOST_ENABLED = 'true';

const FallbackComponent = ({
  error,
  resetErrorBoundary,
}: {
  error: Error;
  resetErrorBoundary: () => void;
}) => <FallbackLayout message={error.message} onClose={resetErrorBoundary} />;

initSentry();
const queryClient = new QueryClient();

const InnerApp = dynamic(
  () =>
    Promise.resolve(({ Component, pageProps }: AppProps) => {
      const storeAccount = useStore((state) => state.polkadotAccount);
      const savePolkadotAccount = useStore((state) => state.savePolkadotAccount);

      return (
        <Sentry.ErrorBoundary
          fallback={({ error, resetError }) => (
            <FallbackLayout message={error.message} onClose={resetError} />
          )}
        >
          <QueryClientProvider client={queryClient}>
            <QueryErrorResetBoundary>
              {({ reset }) => (
                <ErrorBoundary onReset={reset} FallbackComponent={FallbackComponent}>
                  <RainbowKitWrappedProvider config={defaultRainbowConfig}>
                    <PriceOracleProvider>
                      <PolkadotProvider
                        polkadotAppName={POLKADOT_JS_APP_NAME}
                        storeAccount={storeAccount}
                        savePolkadotAccount={savePolkadotAccount}
                      >
                        <EnsureAccount>
                          <StateChainAccountProvider>
                            <FundingSDKProvider>
                              <GlobalScrollbar skin="dark" />
                              <Component {...pageProps} />
                            </FundingSDKProvider>
                          </StateChainAccountProvider>
                        </EnsureAccount>
                      </PolkadotProvider>
                    </PriceOracleProvider>
                  </RainbowKitWrappedProvider>
                  {/* </Sentry.ErrorBoundary> */}
                </ErrorBoundary>
              )}
            </QueryErrorResetBoundary>
          </QueryClientProvider>
          <Toaster expand richColors closeButton theme="dark" visibleToasts={10} />
        </Sentry.ErrorBoundary>
      );
    }),
  { ssr: false },
);

function MyApp(props: AppProps): JSX.Element {
  usePlausible(LP_APP_PLAUSIBLE_DOMAIN);

  const network = getChainflipNetwork();
  const domain = LP_APP_DOMAINS[network === 'unknown' ? 'perseverance' : network];
  const ogType = network === 'mainnet' ? 'mainnet' : 'testnet';

  return (
    <>
      <Head>
        <link rel="icon" href="/chainflip-favicon.ico" sizes="any" />
        <title>Chainflip Liquidity Provision</title>
        <meta
          name="description"
          content="Provide liquidity on the Chainflip protocol and start earning fees"
        />

        <meta property="og:url" content={`https://${domain}`} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Chainflip Liquidity Provision" />
        <meta
          property="og:description"
          content="Provide liquidity on the Chainflip protocol and start earning fees"
        />
        <meta property="og:image" content={`https://${domain}/chainflip-${ogType}-og.png`} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content={domain} />
        <meta property="twitter:url" content={`https://${domain}`} />
        <meta name="twitter:title" content="Chainflip Liquidity Provision" />
        <meta
          name="twitter:description"
          content="Provide liquidity on the Chainflip protocol and start earning fees"
        />
        <meta name="twitter:image" content={`https://${domain}/chainflip-${ogType}-og.png`} />
      </Head>
      <InnerApp {...props} />
    </>
  );
}

export default MyApp;
