import { useGqlQuery } from '@/shared/hooks/useGqlQuery';
import { getAllBoostPoolBalancesForAccount } from '@/shared/queries/boost';
import { isNotNullish } from '../utils';

export type BoostBalancesProps = {
  account?: string;
};
export default function useBoostBalances({ account }: BoostBalancesProps) {
  return useGqlQuery(getAllBoostPoolBalancesForAccount, {
    variables: { accountId: account as string },
    context: { clientName: 'statechainCache' },
    refetchInterval: 5_000,
    enabled: isNotNullish(account),
  });
}
