import {
  type OnboardedContext as IOnboardedContext,
  createStateChainAccountContext,
} from '@/shared/hooks/useStateChainAccount';
import { TokenAmount, entries } from '@/shared/utils';
import { type ChainflipAsset, chainflipAssets, readAssetValue } from '@/shared/utils/chainflip';
import { POLKADOT_JS_APP_NAME } from '../helpers/constants';

type AccountInfo = {
  totalBalance: number | null;
  allBalances: Record<ChainflipAsset, TokenAmount> | null;
};

export const { StateChainAccountProvider, useStateChainAccount } = createStateChainAccountContext({
  parseAccountInfo: (account, assetPrices): AccountInfo => {
    let allBalances: Record<ChainflipAsset, TokenAmount> | null = null;

    if (account?.role === 'liquidity_provider') {
      allBalances = Object.fromEntries(
        chainflipAssets.map((asset) => [
          asset,
          TokenAmount.fromAsset(readAssetValue(account.balances, asset), asset),
        ]),
      ) as Record<ChainflipAsset, TokenAmount>;
    }

    let totalBalance: number | null = null;

    if (allBalances) {
      totalBalance = 0;
      for (const [asset, amount] of entries(allBalances)) {
        const usd = amount.mul(assetPrices[asset] ?? 0).toFixed();
        totalBalance += Number(usd);
      }
    }

    return { totalBalance, allBalances };
  },
  polkadotAppName: POLKADOT_JS_APP_NAME,
  onboardedRole: 'liquidity_provider',
});

export type OnboardedContext = IOnboardedContext<AccountInfo, 'liquidity_provider'>;

export {
  type DepositChannel,
  type SendExtrinsic,
  type EstimateExtrinsicFee,
} from '@/shared/hooks/useStateChainAccount';
