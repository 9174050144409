import { getChainflipId } from '@chainflip/sdk/swap';
import BigNumber from 'bignumber.js';
import useBoostBalances from '@/shared/hooks/useBoostBalances';
import { useGqlQuery } from '@/shared/hooks/useGqlQuery';
import {
  getAllBoostPoolsCacheQuery,
  getBoostPoolApyQuery,
  getEarnedFeesAndVolumeForPools,
  getEarnedFeesByPoolForAccount,
  getLastBoostPoolWithdrawalQuery,
} from '@/shared/queries/boost';
import useRpcRequest from './useRpcRequest';
import { isBoostFeatureEnabled } from '../featureFlags';
import { TokenAmount, isNotNullish, isNullish } from '../utils';
import { internalAssetToRpcAsset, type ChainflipAsset } from '../utils/chainflip';

export type BoostPoolInfoProps = {
  asset: ChainflipAsset;
  feeTier: number;
  account?: string;
};

export default function useBoostPoolInfo({ asset, feeTier, account }: BoostPoolInfoProps) {
  const { data: cacheData } = useGqlQuery(getAllBoostPoolsCacheQuery, {
    context: { clientName: 'statechainCache' },
    refetchInterval: 30_000,
  });

  const { data: accountBalanceData } = useBoostBalances({ account });

  const { data: isWithdrawing = false, refetch: refetchBoostPoolDetails } = useRpcRequest({
    method: 'cf_boost_pool_details',
    args: [internalAssetToRpcAsset[asset]],
    refetchInterval: 30_000,
    enabled: isNotNullish(account),
    select: (result) => {
      const pool = result.find((p) => getChainflipId(p) === asset && p.fee_tier === feeTier);
      return pool?.pending_withdrawals.some((w) => w.account_id === account) ?? false;
    },
  });

  const { data: apyData } = useGqlQuery(getBoostPoolApyQuery, {
    variables: {
      asset: asset as ChainflipAsset,
      feeTierPips: feeTier as number,
      first: 24,
    },
    select: (queryResult): { current: number; last24h?: number } => {
      const apys = queryResult.boostPool?.apys.nodes;

      const current = Number(apys?.[0].projectedApy ?? 0);
      const last24h = apys?.[23] ? Number(apys[23].projectedApy) : undefined;

      return {
        current,
        last24h,
      };
    },
    enabled: Boolean(asset) && !isNullish(feeTier) && isBoostFeatureEnabled(),
    context: { clientName: 'lpProcessor' },
  });

  const cachePool = cacheData?.pools?.nodes.find(
    (pool) => pool.asset === asset && pool.feeTierPips === feeTier,
  );
  const balanceInfo = accountBalanceData?.balances?.nodes.find(
    (balance) => balance.pool.asset === asset && balance.pool.feeTierPips === feeTier,
  );
  const liquidityInfo = isNotNullish(account) ? balanceInfo : cachePool;

  const { data: volumeAndFeeData } = useGqlQuery(getEarnedFeesAndVolumeForPools, {
    context: { clientName: 'lpProcessor' },
    refetchInterval: 30_000,
    enabled: !isNotNullish(account),
  });

  const { data: lpVolumeAndFeeData } = useGqlQuery(getEarnedFeesByPoolForAccount, {
    variables: { accountId: account as string },
    context: { clientName: 'lpProcessor' },
    refetchInterval: 30_000,
    enabled: !isNullish(account),
  });

  const poolId = lpVolumeAndFeeData?.boostPools?.nodes.find(
    (pool) => pool.asset === asset && pool.feeTierPips === feeTier,
  )?.id;

  const { data: lastWithdrawalData } = useGqlQuery(getLastBoostPoolWithdrawalQuery, {
    variables: { accountId: account as string, boostPoolId: poolId as number },
    context: { clientName: 'lpProcessor' },
    refetchInterval: 30_000,
    enabled: !(isNullish(account) || isNullish(poolId)),
  });
  const lastWithdrawalDate = lastWithdrawalData?.account?.balanceChanges.nodes[0]?.event.timestamp;
  const { data: earnedFeesSinceLastWithdrawal } = useGqlQuery(getEarnedFeesByPoolForAccount, {
    variables: { accountId: account as string, since: lastWithdrawalDate },
    context: { clientName: 'lpProcessor' },
    refetchInterval: 30_000,
    enabled: !(isNullish(account) || isNullish(lastWithdrawalDate)),
  });

  const poolVolumeAndFees = volumeAndFeeData?.pools?.nodes.find(
    (info) => info.asset === asset && info.feeTierPips === feeTier,
  )?.shares.aggregates?.sum;

  const lpVolumeAndFees = lpVolumeAndFeeData?.account?.boostShares?.groupedAggregates?.find(
    (info) => info.keys![0] === String(poolId),
  )?.sum;

  const lpVolumeAndFeesSinceLastWithdrawal =
    earnedFeesSinceLastWithdrawal?.account?.boostShares?.groupedAggregates?.find(
      (info) => info.keys![0] === String(poolId),
    )?.sum;

  const volumeAndFees = poolVolumeAndFees ?? lpVolumeAndFees;

  const liquidity = TokenAmount.fromAsset(
    BigNumber.sum(liquidityInfo?.availableAmount ?? 0, liquidityInfo?.unavailableAmount ?? 0),
    asset,
  );
  const liquidityUsd =
    Number(liquidityInfo?.availableAmountValueUsd ?? 0) +
    Number(liquidityInfo?.unavailableAmountValueUsd ?? 0);
  const volume = TokenAmount.fromAsset(volumeAndFees?.amount ?? 0, asset);
  const fees = TokenAmount.fromAsset(volumeAndFees?.fee ?? 0, asset);

  const totalLiquidity = TokenAmount.fromAsset(
    BigNumber.sum(cachePool?.availableAmount ?? 0, cachePool?.unavailableAmount ?? 0),
    asset,
  );

  const feesSinceLastWithdrawal = TokenAmount.fromAsset(
    lpVolumeAndFeesSinceLastWithdrawal?.fee ?? 0,
    asset,
  );

  return {
    liquidity,
    liquidityUsd,
    totalLiquidity,
    volume,
    fees,
    volumeUsd: volumeAndFees?.amountUsd,
    feesUsd: volumeAndFees?.feeUsd,
    feeUsdSinceLastWithdrawal: lpVolumeAndFeesSinceLastWithdrawal?.feeUsd,
    feesSinceLastWithdrawal,
    apy: apyData,
    // apollo query client loading states are shit
    liquidityLoading: liquidityInfo == null,
    volumeLoading: volumeAndFees == null,
    feesLoading: volumeAndFees == null,
    isWithdrawing,
    refetchBoostPoolDetails,
  };
}
